import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../../router'

export default function useList() {
  const toast = useToast()
  const refPaymentRequestListTablePaymentRequests = ref(null)
  const refPaymentRequestListTableSupplies = ref(null)
  const refPaymentRequestListTableHO = ref(null)
  const refPaymentRequestListTableSuppliesNA = ref(null)
  const refPaymentRequestListTablePaymentRequestsNA = ref(null)
  const refPaymentRequestListTableHONA = ref(null)
  const refPaymentRequestListTableFinishedPaymentRequests = ref(null)
  const refPaymentRequestListTableFinishedSupplies = ref(null)
  const refPaymentRequestListTableFinishedHO = ref(null)
  const refPaymentRequestListTableVoid = ref(null)
  const branchFilter = ref(null)
  const tableColumnsFinishedPaymentRequests = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsFinishedSupplies = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsFinishedHO = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsVoid = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'VoidTime', sortable: false },
    { key: 'actions' },
  ]
  const tableColumnsPaymentRequests = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsPaymentRequestsNA = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsSupplies = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsSuppliesNA = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsHO = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsHONA = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsFinished = [
    { key: 'Number', sortable: true },
    { key: 'Project', sortable: true },
    { key: 'Currency', sortable: true },
    { key: 'ClaimedTo', sortable: true },
    { key: 'Total', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const perPagePaymentRequests = ref(10)
  const perPagePaymentRequestsNA = ref(10)
  const perPageFinishedPaymentRequests = ref(10)
  const perPageFinishedPaymentRequestsNA = ref(10)
  const totalPaymentRequests = ref(0)
  const totalPaymentRequestsNA = ref(0)
  const totalFinishedPaymentRequests = ref(0)
  const currentPagePaymentRequests = ref(1)
  const currentPagePaymentRequestsNA = ref(1)
  const currentPageFinishedPaymentRequests = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQueryPaymentRequests = ref('')
  const searchQueryPaymentRequestsNA = ref('')
  const searchQueryFinishedPaymentRequests = ref('')
  const sortByPaymentRequests = ref('id')
  const sortByPaymentRequestsNA = ref('id')
  const isSortDirDescPaymentRequests = ref(true)
  const startDateFilterPaymentRequests = ref('')
  const endDateFilterPaymentRequests = ref('')
  const isSortDirDescPaymentRequestsNA = ref(true)
  const startDateFilterPaymentRequestsNA = ref('')
  const endDateFilterPaymentRequestsNA = ref('')
  const startDateFilterFinishedPaymentRequests = ref('')
  const endDateFilterFinishedPaymentRequests = ref('')
  const perPageSupplies = ref(10)
  const perPageSuppliesNA = ref(10)
  const perPageFinishedSupplies = ref(10)
  const totalSupplies = ref(0)
  const totalSuppliesNA = ref(0)
  const totalFinishedSupplies = ref(0)
  const currentPageSupplies = ref(1)
  const currentPageSuppliesNA = ref(1)
  const currentPageFinishedSupplies = ref(1)
  const searchQuerySupplies = ref('')
  const searchQuerySuppliesNA = ref('')
  const searchQueryFinishedSupplies = ref('')
  const sortBySupplies = ref('id')
  const sortBySuppliesNA = ref('id')
  const isSortDirDescSupplies = ref(true)
  const startDateFilterSupplies = ref('')
  const endDateFilterSupplies = ref('')
  const startDateFilterSuppliesNA = ref('')
  const endDateFilterSuppliesNA = ref('')
  const isSortDirDescSuppliesNA = ref(true)
  const startDateFilterFinishedSupplies = ref('')
  const endDateFilterFinishedSupplies = ref('')
  const perPageHO = ref(10)
  const perPageHONA = ref(10)
  const totalHO = ref(0)
  const currentPageHO = ref(1)
  const currentPageHONA = ref(1)
  const totalHONA = ref(0)
  const searchQueryHO = ref('')
  const searchQueryHONA = ref('')
  const sortByHO = ref('id')
  const sortByHONA = ref('id')
  const isSortDirDescHO = ref(true)
  const isSortDirDescVoid = ref(true)
  const startDateFilterHO = ref('')
  const endDateFilterHO = ref('')
  const isSortDirDescHONA = ref(true)
  const startDateFilterHONA = ref('')
  const endDateFilterHONA = ref('')
  const perPageFinishedHO = ref(10)
  const totalFinishedHO = ref(0)
  const currentPageFinishedHO = ref(1)
  const searchQueryFinishedHO = ref('')
  const startDateFilterFinishedHO = ref('')
  const endDateFilterFinishedHO = ref('')
  const perPageVoid = ref(10)
  const totalVoid = ref(0)
  const currentPageVoid = ref(1)
  const searchQueryVoid = ref('')
  const startDateFilterVoid = ref('')
  const endDateFilterVoid = ref('')

  const dataMeta = computed(() => {
    const localItemsCountPaymentRequests = refPaymentRequestListTablePaymentRequests.value ? refPaymentRequestListTablePaymentRequests.value.localItems.length : 0
    const localItemsCountPaymentRequestsNA = refPaymentRequestListTablePaymentRequestsNA.value ? refPaymentRequestListTablePaymentRequestsNA.value.localItems.length : 0
    const localItemsCountSupplies = refPaymentRequestListTableSupplies.value ? refPaymentRequestListTableSupplies.value.localItems.length : 0
    const localItemsCountSuppliesNA = refPaymentRequestListTableSuppliesNA.value ? refPaymentRequestListTableSuppliesNA.value.localItems.length : 0
    const localItemsCountHO = refPaymentRequestListTableHO.value ? refPaymentRequestListTableHO.value.localItems.length : 0
    const localItemsCountHONA = refPaymentRequestListTableHONA.value ? refPaymentRequestListTableHONA.value.localItems.length : 0
    const localItemsCountVoid = refPaymentRequestListTableVoid.value ? refPaymentRequestListTableVoid.value.localItems.length : 0
    return {
      fromPaymentRequests: perPagePaymentRequests.value * (currentPagePaymentRequests.value - 1) + (localItemsCountPaymentRequests ? 1 : 0),
      toPaymentRequests: perPagePaymentRequests.value * (currentPagePaymentRequests.value - 1) + localItemsCountPaymentRequests,
      ofPaymentRequests: totalPaymentRequests.value,
      fromPaymentRequestsNA: perPagePaymentRequestsNA.value * (currentPagePaymentRequestsNA.value - 1) + (localItemsCountPaymentRequestsNA ? 1 : 0),
      toPaymentRequestsNA: perPagePaymentRequestsNA.value * (currentPagePaymentRequestsNA.value - 1) + localItemsCountPaymentRequestsNA,
      ofPaymentRequestsNA: totalPaymentRequestsNA.value,
      fromSupplies: perPageSupplies.value * (currentPageSupplies.value - 1) + (localItemsCountSupplies ? 1 : 0),
      toSupplies: perPageSupplies.value * (currentPageSupplies.value - 1) + localItemsCountSupplies,
      ofSupplies: totalSupplies.value,
      fromSuppliesNA: perPageSuppliesNA.value * (currentPageSuppliesNA.value - 1) + (localItemsCountSuppliesNA ? 1 : 0),
      toSuppliesNA: perPageSuppliesNA.value * (currentPageSuppliesNA.value - 1) + localItemsCountSuppliesNA,
      ofSuppliesNA: totalSuppliesNA.value,
      fromHO: perPageHO.value * (currentPageHO.value - 1) + (localItemsCountHO ? 1 : 0),
      toHO: perPageHO.value * (currentPageHO.value - 1) + localItemsCountHO,
      ofHO: totalHO.value,
      fromVoid: perPageVoid.value * (currentPageVoid.value - 1) + (localItemsCountVoid ? 1 : 0),
      toVoid: perPageVoid.value * (currentPageVoid.value - 1) + localItemsCountVoid,
      ofVoid: totalVoid.value,
      fromHONA: perPageHONA.value * (currentPageHONA.value - 1) + (localItemsCountHONA ? 1 : 0),
      toHONA: perPageHONA.value * (currentPageHONA.value - 1) + localItemsCountHONA,
      ofHONA: totalHONA.value,
    }
  })

  const dataMetaFinished = computed(() => {
    const localItemsCountFinishedPaymentRequests = refPaymentRequestListTableFinishedPaymentRequests.value ? refPaymentRequestListTableFinishedPaymentRequests.value.localItems.length : 0
    const localItemsCountFinishedSupplies = refPaymentRequestListTableFinishedSupplies.value ? refPaymentRequestListTableFinishedSupplies.value.localItems.length : 0
    const localItemsCountFinishedHO = refPaymentRequestListTableFinishedHO.value ? refPaymentRequestListTableFinishedHO.value.localItems.length : 0
    const localItemsCountVoid = refPaymentRequestListTableVoid.value ? refPaymentRequestListTableVoid.value.localItems.length : 0
    return {
      fromPaymentRequests: perPageFinishedPaymentRequests.value * (currentPageFinishedPaymentRequests.value - 1) + (localItemsCountFinishedPaymentRequests ? 1 : 0),
      toPaymentRequests: perPageFinishedPaymentRequests.value * (currentPageFinishedPaymentRequests.value - 1) + localItemsCountFinishedPaymentRequests,
      ofPaymentRequests: totalFinishedPaymentRequests.value,
      fromSupplies: perPageFinishedSupplies.value * (currentPageFinishedSupplies.value - 1) + (localItemsCountFinishedSupplies ? 1 : 0),
      toSupplies: perPageFinishedSupplies.value * (currentPageFinishedSupplies.value - 1) + localItemsCountFinishedSupplies,
      ofSupplies: totalFinishedSupplies.value,
      fromHO: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + (localItemsCountFinishedHO ? 1 : 0),
      toHO: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + localItemsCountFinishedHO,
      ofHO: totalFinishedHO.value,
      fromVoid: perPageVoid.value * (currentPageVoid.value - 1) + (localItemsCountVoid ? 1 : 0),
      toVoid: perPageVoid.value * (currentPageVoid.value - 1) + localItemsCountVoid,
      ofVoid: totalVoid.value,
    }
  })

  const refetchDataPaymentRequests = () => {
    refPaymentRequestListTablePaymentRequests.value.refresh()
  }
  const refetchDataPaymentRequestsNA = () => {
    refPaymentRequestListTablePaymentRequestsNA.value.refresh()
  }
  const refetchDataFinishedPaymentRequests = () => {
    refPaymentRequestListTableFinishedPaymentRequests.value.refresh()
  }
  const refetchDataSupplies = () => {
    refPaymentRequestListTableSupplies.value.refresh()
  }
  const refetchDataFinishedSupplies = () => {
    refPaymentRequestListTableFinishedSupplies.value.refresh()
  }
  const refetchDataHO = () => {
      refPaymentRequestListTableHO.value.refresh()
  }
  const refetchDataFinishedHO = () => {
    refPaymentRequestListTableFinishedHO.value.refresh()
  }
  const refetchDataVoid = () => {
    refPaymentRequestListTableVoid.value.refresh()
  }
  watch([
    currentPagePaymentRequests,
    perPagePaymentRequests,
    searchQueryPaymentRequests,
    sortByPaymentRequests,
    sortByPaymentRequestsNA,
    isSortDirDescPaymentRequests,
    currentPageFinishedPaymentRequests,
    perPageFinishedPaymentRequests,
    searchQueryFinishedPaymentRequests,
    startDateFilterPaymentRequests,
    endDateFilterPaymentRequests,
    startDateFilterFinishedPaymentRequests,
    endDateFilterFinishedPaymentRequests,
    branchFilter,
    currentPageSupplies,
    perPageSupplies,
    searchQuerySupplies,
    sortBySupplies,
    isSortDirDescSupplies,
    currentPageFinishedSupplies,
    perPageFinishedSupplies,
    searchQueryFinishedSupplies,
    startDateFilterSupplies,
    endDateFilterSupplies,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    currentPageHO,
    perPageHO,
    searchQueryHO,
    sortByHO,
    isSortDirDescHO,
    currentPageFinishedHO,
    perPageFinishedHO,
    searchQueryFinishedHO,
    startDateFilterHO,
    endDateFilterHO,
    startDateFilterFinishedHO,
    endDateFilterFinishedHO,
    currentPagePaymentRequestsNA,
    searchQueryPaymentRequestsNA,
    startDateFilterPaymentRequestsNA,
    endDateFilterPaymentRequestsNA,
    currentPageSuppliesNA,
    searchQuerySuppliesNA,
    sortBySuppliesNA,
    startDateFilterSuppliesNA,
    endDateFilterSuppliesNA,
    currentPageHONA,
    searchQueryHONA,
    sortByHONA,
    isSortDirDescHONA,
    startDateFilterHONA,
    endDateFilterHONA,
    currentPageVoid,
    perPageVoid,
    searchQueryVoid,
    startDateFilterVoid,
    endDateFilterVoid,
  ], (newValues, oldValues) => {
    // Find the index of the changed property
    const changedIndex = newValues.findIndex((value, index) => value !== oldValues[index])

    // Perform actions based on the changed property
    if (changedIndex !== -1) {
      switch (changedIndex) {
        case 0: // currentPagePaymentRequests
          refetchDataPaymentRequests()
          break
        case 1: // currentPagePaymentRequests
          refetchDataPaymentRequests()
          break
        case 2: // currentPagePaymentRequests
          refetchDataPaymentRequests()
          break
        case 3: // currentPagePaymentRequests
          refetchDataPaymentRequests()
          break
        case 4: // currentPagePaymentRequests
          refetchDataPaymentRequests()
          break
        case 5: // refetchDataFinishedPaymentRequests
        refetchDataFinishedPaymentRequests()
          break
        case 6: // refetchDataFinishedPaymentRequests
        refetchDataFinishedPaymentRequests()
          break
        case 7: // refetchDataFinishedPaymentRequests
        refetchDataFinishedPaymentRequests()
          break
        case 8: // refetchDataFinishedPaymentRequests
        refetchDataFinishedPaymentRequests()
          break
        case 9: // refetchDataFinishedPaymentRequests
        refetchDataFinishedPaymentRequests()
          break
        case 10: // refetchDataFinishedPaymentRequests
        refetchDataFinishedPaymentRequests()
          break
        case 11: // refetchDataSupplies
        refetchDataFinishedPaymentRequests()
          break
        case 12: // refetchDataSupplies
        refetchDataSupplies()
          break
        case 14: // refetchDataSupplies
        refetchDataSupplies()
          break
        case 15: // refetchDataSupplies
          refetchDataSupplies()
          break
        case 16: // refetchDataSupplies
          refetchDataSupplies()
          break
        case 17: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 18: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 19: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 20: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 21: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 22: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 23: // refetchDataFinishedSupplies
        refetchDataFinishedSupplies()
          break
        case 24: // refetchDataHO
        refetchDataHO()
          break
        case 25: // refetchDataHO
        refetchDataHO()
          break
        case 26: // refetchDataHO
        refetchDataHO()
          break
        case 27: // refetchDataHO
        refetchDataHO()
          break
        case 28: // refetchDataHO
        refetchDataHO()
          break
        case 29: // refetchDataFinishedHO
        refetchDataFinishedHO()
          break
        case 30: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 31: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 32: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 33: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 34: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 35: // refetchDataFinishedHO
          refetchDataFinishedHO()
          break
        case 36: // refetchDataFinishedHO
          refetchDataPaymentRequestsNA()
          break
          case 37: // refetchDataFinishedHO
            refetchDataPaymentRequestsNA()
            break
          case 38: // refetchDataFinishedHO
            refetchDataPaymentRequestsNA()
            break
          case 39: // refetchDataFinishedHO
            refetchDataPaymentRequestsNA()
            break
                                case 51: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 52: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 53: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 54: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 55: // refetchDataVoid
                                refetchDataVoid()
                                break
                                case 13:
                                  refetchDataPaymentRequests()
                                  refetchDataPaymentRequestsNA()
                                  refetchDataFinishedPaymentRequests()
                                  refetchDataVoid()
                                break
// Add cases for other properties if needed
        default:
          // Default action if none of the specified properties changed
          break
      }
    }
  })

  const fetchPaymentRequests = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPagePaymentRequests.value * (currentPagePaymentRequests.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPagePaymentRequests.value}&offset=${to}&date_start=${startDateFilterPaymentRequests.value}&date_end=${endDateFilterPaymentRequests.value}&search=${searchQueryPaymentRequests.value}&branch=${branchId}`, { headers })
      .then(response => {
        totalPaymentRequests.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPaymentRequestsNA = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPagePaymentRequestsNA.value * (currentPagePaymentRequestsNA.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPagePaymentRequestsNA.value}&offset=${to}&date_start=${startDateFilterPaymentRequestsNA.value}&date_end=${endDateFilterPaymentRequestsNA.value}&search=${searchQueryPaymentRequestsNA.value}&needs_approval=true&branch=${branchId}`, { headers })
      .then(response => {
        totalPaymentRequestsNA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchFinishedPaymentRequests = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedPaymentRequests.value * (currentPageFinishedPaymentRequests.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageFinishedPaymentRequests.value}&offset=${to}&is_finished=true&date_start=${startDateFilterFinishedPaymentRequests.value}&date_end=${endDateFilterFinishedPaymentRequests.value}&search=${searchQueryFinishedPaymentRequests.value}&branch=${branchId}`, { headers })
      .then(response => {
        totalFinishedPaymentRequests.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchSupplies = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageSupplies.value * (currentPageSupplies.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageSupplies.value}&offset=${to}&date_start=${startDateFilterSupplies.value}&date_end=${endDateFilterSupplies.value}&search=${searchQuerySupplies.value}&branch=${branchId}`, { headers })
      .then(response => {
        totalSupplies.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchSuppliesNA = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageSuppliesNA.value * (currentPageSuppliesNA.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageSuppliesNA.value}&offset=${to}&date_start=${startDateFilterSuppliesNA.value}&date_end=${endDateFilterSuppliesNA.value}&search=${searchQuerySuppliesNA.value}&branch=${branchId}`, { headers })
      .then(response => {
        totalSuppliesNA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchFinishedSupplies = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedSupplies.value * (currentPageFinishedSupplies.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageFinishedSupplies.value}&offset=${to}&is_finished=true&date_start=${startDateFilterFinishedSupplies.value}&date_end=${endDateFilterFinishedSupplies.value}&search=${searchQueryFinishedSupplies.value}&branch=${branchId}`, { headers })
      .then(response => {
        totalFinishedSupplies.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageHO.value * (currentPageHO.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageHO.value}&offset=${to}&date_start=${startDateFilterHO.value}&date_end=${endDateFilterHO.value}&search=${searchQueryHO.value}&type=center&branch=${branchId}`, { headers })
      .then(response => {
        totalHO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchHONA = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageHONA.value * (currentPageHONA.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageHONA.value}&offset=${to}&date_start=${startDateFilterHONA.value}&date_end=${endDateFilterHONA.value}&search=${searchQueryHONA.value}&branch=${branchId}`, { headers })
      .then(response => {
        totalHONA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchFinishedHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedHO.value * (currentPageFinishedHO.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageFinishedHO.value}&offset=${to}&is_finished=true&date_start=${startDateFilterFinishedHO.value}&date_end=${endDateFilterFinishedHO.value}&search=${searchQueryFinishedHO.value}&type=center&branch=${branchId}`, { headers })
      .then(response => {
        totalFinishedHO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPaymentRequestsVoid = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageVoid.value * (currentPageVoid.value - 1)
    const branchId = branchFilter.value !== null ? branchFilter.value.value : ''
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUESTS}?limit=${perPageVoid.value}&offset=${to}&date_start=${startDateFilterVoid.value}&date_end=${endDateFilterVoid.value}&void=true&branch=${branchId}`, { headers })
      .then(response => {
        totalVoid.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchPaymentRequests,
    fetchPaymentRequestsNA,
    fetchFinishedPaymentRequests,
    fetchSupplies,
    fetchSuppliesNA,
    fetchFinishedSupplies,
    fetchHO,
    fetchHONA,
    fetchFinishedHO,
    fetchPaymentRequestsVoid,
    perPageOptions,
    tableColumnsPaymentRequests,
    tableColumnsFinishedPaymentRequests,
    tableColumnsSupplies,
    tableColumnsFinishedSupplies,
    tableColumnsHO,
    tableColumnsFinishedHO,
    tableColumnsVoid,
    perPagePaymentRequests,
    perPageFinishedPaymentRequests,
    totalPaymentRequests,
    totalFinishedPaymentRequests,
    currentPagePaymentRequests,
    currentPageFinishedPaymentRequests,
    perPageSupplies,
    perPageFinishedSupplies,
    totalSupplies,
    totalFinishedSupplies,
    currentPageSupplies,
    currentPageFinishedSupplies,
    perPageHO,
    perPageFinishedHO,
    perPageVoid,
    totalHO,
    totalFinishedHO,
    totalVoid,
    currentPageHO,
    currentPageFinishedHO,
    currentPageVoid,
    searchQueryPaymentRequests,
    searchQueryFinishedPaymentRequests,
    sortByPaymentRequests,
    isSortDirDescPaymentRequests,
    startDateFilterPaymentRequests,
    endDateFilterPaymentRequests,
    startDateFilterFinishedPaymentRequests,
    endDateFilterFinishedPaymentRequests,
    searchQuerySupplies,
    searchQueryFinishedSupplies,
    sortBySupplies,
    isSortDirDescSupplies,
    startDateFilterSupplies,
    endDateFilterSupplies,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    searchQueryHO,
    searchQueryFinishedHO,
    searchQueryVoid,
    sortByHO,
    isSortDirDescHO,
    startDateFilterHO,
    endDateFilterHO,
    startDateFilterFinishedHO,
    endDateFilterFinishedHO,
    startDateFilterVoid,
    endDateFilterVoid,
    dataMeta,
    dataMetaFinished,
    tableColumnsFinished,
    refPaymentRequestListTablePaymentRequests,
    refPaymentRequestListTableSupplies,
    refPaymentRequestListTableHO,
    refPaymentRequestListTableFinishedPaymentRequests,
    refPaymentRequestListTableFinishedSupplies,
    refPaymentRequestListTableFinishedHO,
    refPaymentRequestListTableVoid,
    perPagePaymentRequestsNA,
    perPageFinishedPaymentRequestsNA,
    totalPaymentRequestsNA,
    currentPagePaymentRequestsNA,
    searchQueryPaymentRequestsNA,
    sortByPaymentRequestsNA,
    isSortDirDescPaymentRequestsNA,
    startDateFilterPaymentRequestsNA,
    endDateFilterPaymentRequestsNA,
    tableColumnsPaymentRequestsNA,
    perPageSuppliesNA,
    totalSuppliesNA,
    currentPageSuppliesNA,
    searchQuerySuppliesNA,
    sortBySuppliesNA,
    isSortDirDescSuppliesNA,
    startDateFilterSuppliesNA,
    endDateFilterSuppliesNA,
    tableColumnsSuppliesNA,
    perPageHONA,
    totalHONA,
    currentPageHONA,
    searchQueryHONA,
    sortByHONA,
    isSortDirDescHONA,
    startDateFilterHONA,
    endDateFilterHONA,
    tableColumnsHONA,
    refPaymentRequestListTableSuppliesNA,
    refPaymentRequestListTablePaymentRequestsNA,
    refPaymentRequestListTableHONA,
    refetchDataPaymentRequests,
    refetchDataFinishedPaymentRequests,
    refetchDataSupplies,
    refetchDataFinishedSupplies,
    refetchDataHO,
    refetchDataFinishedHO,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchDataVoid,
    isSortDirDescVoid,
    branchFilter,
  }
}
