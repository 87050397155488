<template>
  <div>
    <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      v-model="modalMessage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              :ref="messageListRef"
              v-if="messagesList.length > 0"
              class="list-group"
              style="height:300px;width:100%;overflow-y:scroll;"
            >
              <li
                v-for="item in messagesList"
                :key="item"
                class="list-group-item"
              >
                <div
                  v-if="userId.toString() == item.user_id.toString()"
                  class="float-right"
                >
                  <h5>Me</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
                <div
                  v-if="userId.toString() != item.user_id.toString()"
                  class="float-left"
                >
                  <h5>{{ item.user_fullname }}</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
              </li>
            </ul>
            <div v-if="messagesList.length == 0">
              No message
            </div>
            <br />
            <form id="send_message_form" method="post" action="">
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="messageBox"
                    type="text"
                    class="form-control"
                    placeholder="Write your message"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendMessage()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
   <!-- Modal Close Payment Request -->
   <b-modal
  id="modal-close-request"
  v-model="modalClosePR"
  cancel-variant="outline-secondary"
  hide-footer
  centered
  title="Void Payment Request"
>
  <b-form @submit.prevent="voidPRProcess()">
    <div>
      <div class="text-center">
        <p>Are you sure you want to Void this Payment Request?</p>
        <b-button type="submit" variant="primary" class="mr-2">Yes</b-button>
        <b-button @click="modalClosePR = false" variant="secondary">No</b-button>
      </div>
    </div>
  </b-form>
</b-modal>
   <!-- Modal Revise Request -->
   <b-modal
  id="modal-revise-request"
  v-model="modalReject"
  cancel-variant="outline-secondary"
  hide-footer
  centered
  title="Revise Request"
>
  <b-form @submit.prevent="sendReject">
    <div>
      <div class="text-center">
        <p>Are you sure you want to Reject this Payment Request?</p>
        <b-button type="submit" variant="primary" class="mr-2">Yes</b-button>
        <b-button @click="modalReject = false" variant="secondary">No</b-button>
      </div>
    </div>
  </b-form>
</b-modal>
    <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      v-model="modalMessage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              :ref="messageListRef"
              v-if="messagesList.length > 0"
              class="list-group"
              style="height:300px;width:100%;overflow-y:scroll"
            >
              <li
                v-for="item in messagesList"
                :key="item"
                class="list-group-item"
              >
                <div
                  v-if="userId.toString() == item.user_id.toString()"
                  class="float-right"
                >
                  <h5>Me</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
                <div
                  v-if="userId.toString() != item.user_id.toString()"
                  class="float-left"
                >
                  <h5>{{ item.user_fullname }}</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
              </li>
            </ul>
            <div v-if="messagesList.length == 0">
              No message
            </div>
            <br />
            <form id="send_message_form" method="post" action="">
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="messageBox"
                    type="text"
                    class="form-control"
                    placeholder="Write your message"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendMessage()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Upload-->
    <b-modal
      id="modal-upload"
      v-model="modalUpload"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Documents"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              v-if="documentsList.length > 0"
              class="list-group"
              style="height:300px;width:100%;overflow-y:scroll"
            >
              <input
                id="materialRequestId"
                ref="invoiceMessage"
                v-model="materialRequestId"
                type="hidden"
                name="materialRequestId"
              />
              <li
  v-for="item in documentsList"
  :key="item"
  class="list-group-item d-flex justify-content-between align-items-center"
  style="cursor: pointer;"
>
  <span style="font-size:9px">{{ item.document_file_name }}</span>
  <div>
    <b-button
  :disabled="item.document_old"
  variant="primary"
  size="sm"
  class="mr-1"
  @click.stop="downloadAttachment(item)"
>
      <feather-icon icon="DownloadIcon" />
    </b-button>
    <b-button variant="danger" size="sm" @click.stop="deleteAttachment(item)">
      <feather-icon icon="TrashIcon" />
    </b-button>
  </div>
</li>
            </ul>
            <div v-if="documentsList.length == 0">
              No Files
            </div>
            <br />
            <form
              id="send_file_form"
              method="post"
              @submit.prevent="sendfiles"
              action=""
            >
              <b-row>
                <b-col cols="11" md="9">
          <b-form-file
            id="file"
            ref="uploadFile"
            placeholder="Choose one file or drop it here..."
drop-placeholder="Drop a file here..."
type="file"
@change="handleFilesUpload"
          />
          <div class="alert alert-info" role="alert">
                Max file upload is 2MB
              </div>
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendDocument()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-row>
        <b-col cols="12" md="12" class="d-flex justify-content-end align-items-start">
      <b-col cols="4" class="mt-2">
        <label>Branch</label>
        <v-select-new v-model="branchFilter" :options="branches" />
      </b-col>
    </b-col>
      <b-col cols="12" md="12">
                <div class="float-right add-btn">
                  <b-button
                    v-if="userRole=='finance' || userRole=='super-admin'"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Payment Request</span>
                  </b-button>
                </div>
              </b-col>
              </b-row>
      <b-tabs>
        <b-tab title="Active">
      <b-tabs>
        <b-tab title="Needs Approval">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterPaymentRequestsNA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterPaymentRequestsNA" class="mb-2"></b-form-datepicker>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPagePaymentRequestsNA"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryPaymentRequestsNA"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPaymentRequestListTablePaymentRequestsNA"
            class="position-relative"
            :items="fetchPaymentRequestsNA"
            responsive
            :fields="tableColumnsPaymentRequestsNA"
            primary-key="id"
            :sort-by.sync="sortByPaymentRequestsNA"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescPaymentRequestsNA"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Project -->
            <template #cell(Project)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_project_name
                }}</span>
              </div>
            </template>
            <!-- Column: Currency -->
            <template #cell(Currency)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.currency.currency_full_name
                }}</span>
              </div>
            </template>
            <!-- Column: ClaimedTo -->
            <template #cell(ClaimedTo)="data">
              {{
                data.item.payment_request_claimed_to !== ""
                  ? data.item.payment_request_claimed_to
                  : "-"
              }}
            </template>
            <!-- Column: Total -->
            <template #cell(Total)="data">
  <div class="currency-wrapper">
    <span class="currency-symbol">{{ data.item.currency.currency_symbol }}</span>
    <span class="amount">{{ numberFormat(data.item.payment_request_amount_total) }}</span>
  </div>
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.payment_request_note, 30) }}
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.payment_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
          <b-dropdown-item
                  v-if="
                      data.item.payment_request_confirmed_by_lab_manager == null
                  "
                  @click="editPaymentRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userRole=='fa-coal-manager'" @click="voidPR(data.item.payment_request_id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Void</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userRole!='finance' && data.item.payment_request_is_rejected!=true" @click="rejectPR(data.item.payment_request_id)">
                  <feather-icon icon="XCircleIcon" /> <!-- Changed to XCircleIcon -->
                  <span class="align-middle ml-50">Reject</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests By Lab Manager -->
                <b-dropdown-item
                  v-if="userRole =='laboratory-manager' && data.item.payment_request_confirmed_by_lab_manager == null"
                  @click="
                    confirmPaymentRequest('lab_manager',
                      data.item
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Regional Manager-->
                <b-dropdown-item
                  v-if="userRole =='regional-manager'  &&
                    data.item.payment_request_confirmed_by_lab_manager != null &&
                      data.item
                        .payment_request_confirmed_by_area_manager == null
                  "
                  @click="
                    confirmPaymentRequest('regional_manager', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By Division Head -->
                <b-dropdown-item
                  v-if="userRole =='division-head' &&
                    data.item.payment_request_confirmed_by_area_manager != null &&
                      data.item
                        .payment_request_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPaymentRequest('division_head', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm By FA Coal Manager -->
                <b-dropdown-item
                  v-if="userRole =='fa-coal-manager' &&
                  data.item.payment_request_confirmed_by_division_head!=null &&
                      data.item
                        .payment_request_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPaymentRequest('fa_coal_manager', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By Operational Director -->
                <b-dropdown-item
                  v-if="userRole =='operation-director' &&
                    data.item.payment_request_confirmed_by_fa_coal_manager != null &&
                      data.item.payment_request_confirmed_by_operational_director == null
                  "
                  @click="
                    confirmPaymentRequest('operation_director', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By Vice President-->
                <b-dropdown-item
                  v-if="userRole =='vice-president' &&
                      data.item.payment_request_confirmed_by_operational_director != null &&
                      data.item.payment_request_confirmed_by_vice_president == null
                  "
                  @click="
                    confirmPaymentRequest('vice_president',data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By FA Coal Manager -->
                <b-dropdown-item
                  v-if="userRole =='president-director' &&
                      data.item.payment_request_confirmed_by_vice_president != null&&
                      data.item.payment_request_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPaymentRequest('president_director', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePaymentRequest(data.item.payment_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromPaymentRequestsNA }} to {{ dataMeta.toPaymentRequestsNA}} of
                  {{ dataMeta.ofPaymentRequestsNA }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPagePaymentRequestsNA"
                  :total-rows="totalPaymentRequestsNA"
                  :per-page="perPagePaymentRequestsNA"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="On Going">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterPaymentRequests" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterPaymentRequests" class="mb-2"></b-form-datepicker>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPagePaymentRequests"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryPaymentRequests"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPaymentRequestListTablePaymentRequests"
            class="position-relative"
            :items="fetchPaymentRequests"
            responsive
            :fields="tableColumnsPaymentRequests"
            primary-key="id"
            :sort-by.sync="sortByPaymentRequests"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescPaymentRequests"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap"><feather-icon icon="RefreshCwIcon" v-if="data.item.payment_request_is_revised==true" class="danger"/>
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Project -->
            <template #cell(Project)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_project_name
                }}</span>
              </div>
            </template>
            <!-- Column: Currency -->
            <template #cell(Currency)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.currency.currency_full_name
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(ClaimedTo)="data">
              {{
                data.item.payment_request_claimed_to
              }}
            </template>
            <!-- Column: Total -->
            <template #cell(Total)="data">
  <div class="currency-wrapper">
    <span class="currency-symbol">{{ data.item.currency.currency_symbol }}</span>
    <span class="amount">{{ numberFormat(data.item.payment_request_amount_total) }}</span>
  </div>
</template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.payment_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
          <b-dropdown-item
                  v-if="
                      data.item.payment_request_confirmed_by_lab_manager == null
                  "
                  @click="editPaymentRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userRole=='fa-coal-manager'" @click="voidPR(data.item.payment_request_id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Void</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userRole!='finance' && data.item.payment_request_is_rejected!=true" @click="rejectPR(data.item.payment_request_id)">
                  <feather-icon icon="XCircleIcon" /> <!-- Changed to XCircleIcon -->
                  <span class="align-middle ml-50">Reject</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests By Lab Manager -->
                <b-dropdown-item
                  v-if="userRole =='laboratory-manager' && data.item.payment_request_confirmed_by_lab_manager == null"
                  @click="
                    confirmPaymentRequest('lab_manager',
                      data.item
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Regional Manager-->
                <b-dropdown-item
                  v-if="userRole =='regional-manager'  &&
                    data.item.payment_request_confirmed_by_lab_manager != null &&
                      data.item
                        .payment_request_confirmed_by_area_manager == null
                  "
                  @click="
                    confirmPaymentRequest('regional_manager', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By Division Head -->
                <b-dropdown-item
                  v-if="userRole =='division-head' &&
                    data.item.payment_request_confirmed_by_area_manager != null &&
                      data.item
                        .payment_request_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPaymentRequest('division_head', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm By FA Coal Manager -->
                <b-dropdown-item
                  v-if="userRole =='fa-coal-manager' &&
                  data.item.payment_request_confirmed_by_fa_coal_manager!=null &&
                      data.item
                        .payment_request_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPaymentRequest('fa_coal_manager', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By Operational Director -->
                <b-dropdown-item
                  v-if="userRole =='operation-director' &&
                    data.item.payment_request_confirmed_by_fa_coal_manager != null &&
                      data.item.payment_request_confirmed_by_operational_director == null
                  "
                  @click="
                    confirmPaymentRequest('operation_director', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By Vice President-->
                <b-dropdown-item
                  v-if="userRole =='vice-president' &&
                      data.item.payment_request_confirmed_by_operational_director != null &&
                      data.item.payment_request_confirmed_by_vice_president == null
                  "
                  @click="
                    confirmPaymentRequest('vice_president',data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm PaymentRequests/ By FA Coal Manager -->
                <b-dropdown-item
                  v-if="userRole =='president-director' &&
                      data.item.payment_request_confirmed_by_vice_president != null&&
                      data.item.payment_request_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPaymentRequest('president_director', data.item)
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePaymentRequest(data.item.payment_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromPaymentRequests }} to {{ dataMeta.toPaymentRequests }} of
                  {{ dataMeta.ofPaymentRequests }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPagePaymentRequests"
                  :total-rows="totalPaymentRequests"
                  :per-page="perPagePaymentRequests"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Finished">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterFinishedPaymentRequests" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterFinishedPaymentRequests" class="mb-2"></b-form-datepicker>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageFinishedPaymentRequests"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryFinishedPaymentRequests"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPaymentRequestListTableFinishedPaymentRequests"
            class="position-relative"
            :items="fetchFinishedPaymentRequests"
            responsive
            :fields="tableColumnsFinishedPaymentRequests"
            primary-key="id"
            :sort-by.sync="sortByPaymentRequests"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescPaymentRequests"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap"><feather-icon icon="RefreshCwIcon" v-if="data.item.payment_request_is_revised==true" class="danger"/>
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Project -->
            <template #cell(Project)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_project_name
                }}</span>
              </div>
            </template>
            <!-- Column: Currency -->
            <template #cell(Currency)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.currency.currency_full_name
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(ClaimedTo)="data">
              {{
                data.item.payment_request_claimed_to
              }}
            </template>
            <!-- Column: Total -->
            <template #cell(Total)="data">
  <div class="currency-wrapper">
    <span class="currency-symbol">{{ data.item.currency.currency_symbol }}</span>
    <span class="amount">{{ numberFormat(data.item.payment_request_amount_total) }}</span>
  </div>
</template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.payment_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaFinished.fromPaymentRequests }} to {{ dataMetaFinished.toPaymentRequests }} of
                  {{ dataMetaFinished.ofPaymentRequests }} entries</span
                ><span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director,
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinishedPaymentRequests"
                  :total-rows="totalPaymentRequestsFinishedPaymentRequests"
                  :per-page="perPageFinishedPaymentRequests"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
      </b-tab>
      <b-tab title="Void">

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="5">
            <label>Tanggal Awal</label>
            <b-form-datepicker id="start-datepicker" v-model="startDateFilterVoid" class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col cols="5">
            <label>Tanggal Akhir</label>
            <b-form-datepicker id="end-datepicker" v-model="endDateFilterVoid" class="mb-2"></b-form-datepicker>
          </b-col>
        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPageVoid"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQueryVoid"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPaymentRequestListTableVoid"
        class="position-relative"
        :items="fetchPaymentRequestsVoid"
        responsive
        :fields="tableColumnsVoid"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap"><feather-icon icon="RefreshCwIcon" v-if="data.item.payment_request_is_revised==true" class="danger"/>
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Project -->
            <template #cell(Project)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.payment_request_project_name
                }}</span>
              </div>
            </template>
            <!-- Column: Currency -->
            <template #cell(Currency)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.currency.currency_full_name
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(ClaimedTo)="data">
              {{
                data.item.payment_request_claimed_to
              }}
            </template>
            <!-- Column: Total -->
            <template #cell(Total)="data">
  <div class="currency-wrapper">
    <span class="currency-symbol">{{ data.item.currency.currency_symbol }}</span>
    <span class="amount">{{ numberFormat(data.item.payment_request_amount_total) }}</span>
  </div>
</template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.payment_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
        <!-- Column: VoidBy -->
        <template #cell(VoidBy)="data">
          <b-badge pill class="text-capitalize">
            {{
              data.item.deleter
                ? data.item.deleter.user_fullname
                : "-"
            }}
          </b-badge>
        </template>
        <!-- Column: VoidTime -->
        <template #cell(VoidTime)="data">
          {{
            data.item.payment_request_deleted_time?
            dateSimple(
              data.item.payment_request_deleted_time,
              "YYYY-MM-DD HH:mm:ss"
            ):"-"
          }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail(data.item)">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.fromVoid }} to {{ dataMeta.toVoid }} of
              {{ dataMeta.ofVoid }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPageVoid"
              :total-rows="totalPaymentRequestsVoid"
              :per-page="perPageVoid"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
  </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BFormDatepicker,
  BForm,
  BFormFile,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import moment from "moment"
import { saveAs } from 'file-saver'
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
// import PaymentRequestsListFilters from './PaymentRequestsListFilters.vue'
import PaymentRequestList from "./List"

const userRole = ""

const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const paymentRequestId = ""
const chosenClosePR = ""
const modalReject = false
const modalClosePR = false
const messagesList = []
const branches = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
const modalUpload = false
const documentsList = []
const prId = ''
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // PaymentRequestsListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTab,
    BTabs,
    BFormDatepicker,
    BForm,
    BFormFile,
  },
  data() {
    return {
      branches,
      modalMessage,
      chosenClosePR,
      prId,
      modalClosePR,
      modalReject,
      modalUpload,
      documentsList,
      items,
      statusItems,
      paymentRequestId,
      userId,
      messagesList,
      isLoadingExport: false,
      isLoadingExportFinished: false,
      userdata: getUserData(),
      messageListRef: 1,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    this.getBranches()
    this.$root.$on("refreshTable", text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refPaymentRequestListTablePaymentRequests.refresh()
      this.$refs.refPaymentRequestListTableSupplies.refresh()
      this.$refs.refPaymentRequestListTableHO.refresh()
      this.$refs.refPaymentRequestListTableFinishedPaymentRequests.refresh()
      this.$refs.refPaymentRequestListTableFinishedSupplies.refresh()
      this.$refs.refPaymentRequestListTableFinishedHO.refresh()
      this.$refs.refPaymentRequestListTableVoid.refresh()
    })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-payment-request"
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewPaymentRequestSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      isSortDirDescVoid,
      branchFilter,
    fetchPaymentRequests,
    fetchPaymentRequestsNA,
    fetchFinishedPaymentRequests,
    fetchPaymentRequestsSupplies,
    fetchPaymentRequestsSuppliesNA,
    fetchPaymentRequestsFinishedSupplies,
    fetchPaymentRequestsHO,
    fetchPaymentRequestsHONA,
    fetchPaymentRequestsFinishedHO,
    fetchPaymentRequestsVoid,
    perPageOptions,
    tableColumnsPaymentRequests,
    tableColumnsPaymentRequestsNA,
    tableColumnsFinishedPaymentRequests,
    tableColumnsSupplies,
    tableColumnsSuppliesNA,
    tableColumnsFinishedSupplies,
    tableColumnsHO,
    tableColumnsHONA,
    tableColumnsFinishedHO,
    tableColumnsVoid,
    perPagePaymentRequests,
    perPagePaymentRequestsNA,
    perPageFinishedPaymentRequests,
    totalPaymentRequests,
    totalPaymentRequestsNA,
    totalPaymentRequestsFinishedPaymentRequests,
    currentPagePaymentRequests,
    currentPagePaymentRequestsNA,
    currentPageFinishedPaymentRequests,
    perPageSupplies,
    perPageSuppliesNA,
    perPageFinishedSupplies,
    totalPaymentRequestsSupplies,
    totalPaymentRequestsSuppliesNA,
    totalPaymentRequestsFinishedSupplies,
    currentPageSupplies,
    currentPageSuppliesNA,
    currentPageFinishedSupplies,
    perPageHO,
    perPageHONA,
    perPageFinishedHO,
    perPageVoid,
    totalPaymentRequestsHO,
    totalPaymentRequestsHONA,
    totalPaymentRequestsFinishedHO,
    totalPaymentRequestsVoid,
    currentPageHO,
    currentPageHONA,
    currentPageFinishedHO,
    currentPageVoid,
    searchQueryPaymentRequests,
    searchQueryPaymentRequestsNA,
    searchQueryFinishedPaymentRequests,
    sortByPaymentRequests,
    sortByPaymentRequestsNA,
    isSortDirDescPaymentRequests,
    isSortDirDescPaymentRequestsNA,
    startDateFilterPaymentRequests,
    startDateFilterPaymentRequestsNA,
    endDateFilterPaymentRequests,
    endDateFilterPaymentRequestsNA,
    startDateFilterFinishedPaymentRequests,
    endDateFilterFinishedPaymentRequests,
    searchQuerySupplies,
    searchQuerySuppliesNA,
    searchQueryFinishedSupplies,
    sortBySupplies,
    sortBySuppliesNA,
    isSortDirDescSupplies,
    isSortDirDescSuppliesNA,
    startDateFilterSupplies,
    startDateFilterSuppliesNA,
    endDateFilterSupplies,
    endDateFilterSuppliesNA,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    searchQueryHO,
    searchQueryHONA,
    searchQueryFinishedHO,
    searchQueryVoid,
    sortByHO,
    sortByHONA,
    isSortDirDescHO,
    isSortDirDescHONA,
    startDateFilterHO,
    startDateFilterHONA,
    endDateFilterHO,
    endDateFilterHONA,
    startDateFilterFinishedHO,
    endDateFilterFinishedHO,
    startDateFilterVoid,
    endDateFilterVoid,
    dataMeta,
    dataMetaFinished,
    tableColumnsFinished,
    refPaymentRequestListTablePaymentRequests,
    refPaymentRequestListTablePaymentRequestsNA,
    refPaymentRequestListTableSuppliesNA,
    refPaymentRequestListTableHONA,
    refPaymentRequestListTableSupplies,
    refPaymentRequestListTableHO,
    refPaymentRequestListTableFinishedPaymentRequests,
    refPaymentRequestListTableFinishedSupplies,
    refPaymentRequestListTableFinishedHO,
    refPaymentRequestListTableVoid,
    refetchDataPaymentRequests,
    refetchDataFinishedPaymentRequests,
    refetchDataSupplies,
    refetchDataFinishedSupplies,
    refetchDataHO,
    refetchDataFinishedHO,
    refetchDataVoid,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
      // UI
      resolvePaymentRequestRoleVariant,
      resolvePaymentRequestRoleIcon,
      resolvePaymentRequestStatusVariant,
    } = PaymentRequestList()

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ]

    return {
      isSortDirDescVoid,
      branchFilter,
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewPaymentRequestSidebarActive,
      userRole,
    fetchPaymentRequests,
    fetchPaymentRequestsNA,
    fetchFinishedPaymentRequests,
    fetchPaymentRequestsSupplies,
    fetchPaymentRequestsSuppliesNA,
    fetchPaymentRequestsFinishedSupplies,
    fetchPaymentRequestsHO,
    fetchPaymentRequestsHONA,
    fetchPaymentRequestsFinishedHO,
    fetchPaymentRequestsVoid,
    perPageOptions,
    tableColumnsPaymentRequests,
    tableColumnsFinishedPaymentRequests,
    tableColumnsSupplies,
    tableColumnsFinishedSupplies,
    tableColumnsHO,
    tableColumnsFinishedHO,
    tableColumnsVoid,
    perPagePaymentRequests,
    perPageFinishedPaymentRequests,
    totalPaymentRequests,
    totalPaymentRequestsFinishedPaymentRequests,
    currentPagePaymentRequests,
    currentPageFinishedPaymentRequests,
    perPageSupplies,
    perPageFinishedSupplies,
    totalPaymentRequestsSupplies,
    totalPaymentRequestsFinishedSupplies,
    currentPageSupplies,
    currentPageFinishedSupplies,
    perPageHO,
    perPageFinishedHO,
    perPageVoid,
    totalPaymentRequestsHO,
    totalPaymentRequestsFinishedHO,
    totalPaymentRequestsVoid,
    currentPageHO,
    currentPageFinishedHO,
    currentPageVoid,
    searchQueryPaymentRequests,
    searchQueryFinishedPaymentRequests,
    sortByPaymentRequests,
    isSortDirDescPaymentRequests,
    startDateFilterPaymentRequests,
    endDateFilterPaymentRequests,
    startDateFilterFinishedPaymentRequests,
    endDateFilterFinishedPaymentRequests,
    searchQuerySupplies,
    searchQueryFinishedSupplies,
    sortBySupplies,
    isSortDirDescSupplies,
    startDateFilterSupplies,
    endDateFilterSupplies,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    searchQueryHO,
    searchQueryFinishedHO,
    searchQueryVoid,
    sortByHO,
    isSortDirDescHO,
    startDateFilterHO,
    endDateFilterHO,
    startDateFilterFinishedHO,
    startDateFilterVoid,
    endDateFilterFinishedHO,
    endDateFilterVoid,
    dataMeta,
    dataMetaFinished,
    tableColumnsFinished,
    tableColumnsPaymentRequestsNA,
    tableColumnsSuppliesNA,
    tableColumnsHONA,
    perPagePaymentRequestsNA,
    totalPaymentRequestsNA,
    currentPagePaymentRequestsNA,
    perPageSuppliesNA,
    totalPaymentRequestsSuppliesNA,
    currentPageSuppliesNA,
    perPageHONA,
    totalPaymentRequestsHONA,
    currentPageHONA,
    searchQueryPaymentRequestsNA,
    sortByPaymentRequestsNA,
    isSortDirDescPaymentRequestsNA,
    startDateFilterPaymentRequestsNA,
    endDateFilterPaymentRequestsNA,
    searchQuerySuppliesNA,
    sortBySuppliesNA,
    isSortDirDescSuppliesNA,
    startDateFilterSuppliesNA,
    endDateFilterSuppliesNA,
    searchQueryHONA,
    sortByHONA,
    isSortDirDescHONA,
    startDateFilterHONA,
    endDateFilterHONA,
    refPaymentRequestListTablePaymentRequests,
    refPaymentRequestListTableSupplies,
    refPaymentRequestListTableHO,
    refPaymentRequestListTableFinishedPaymentRequests,
    refPaymentRequestListTableFinishedSupplies,
    refPaymentRequestListTableFinishedHO,
    refPaymentRequestListTableVoid,
    refPaymentRequestListTablePaymentRequestsNA,
    refPaymentRequestListTableSuppliesNA,
    refPaymentRequestListTableHONA,
    refetchDataPaymentRequests,
    refetchDataFinishedPaymentRequests,
    refetchDataSupplies,
    refetchDataFinishedSupplies,
    refetchDataHO,
    refetchDataFinishedHO,
    refetchDataVoid,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,

      // Filter
      avatarText,

      // UI
      resolvePaymentRequestRoleVariant,
      resolvePaymentRequestRoleIcon,
      resolvePaymentRequestStatusVariant,
      roleOptions,
      statusOptions,
    }
  },
  methods: {
    getBranches() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
        axios
            .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`, { headers })
            .then(response => {
              const branchesJoin = response.data.data.map(elem => (
                { label: `${elem.branch_name} (${elem.branch_code})`, value: elem.branch_id }
              ))
              this.branches = branchesJoin
            })
            .catch(err => {
                console.log(err)
            })
    },
    confirmPaymentRequest(role, details) {
      localStorage.setItem("paymentRequestConformRole", role)
      localStorage.setItem("paymentRequestDetails", JSON.stringify(details))
      this.$router.push({ name: "apps-payment-request-confirm" })
    },
    numberFormat(value) {
    if (Number.isNaN(value)) return "0,00"
    return new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
  },
  sendReject() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PAYMENT_REQUEST_REJECT}`
      const body = {
        id: this.chosenClosePR,
        time: moment(),
      }
      axios.post(url, body, {
          headers,
      }).then(response => {
        if (response.data.success === true) {
          this.modalReject = false
            this.$refs.refPaymentRequestListTablePaymentRequests.refresh()
            this.$refs.refPaymentRequestListTablePaymentRequestsNA.refresh()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Reject PR Success",
            icon: "CheckIcon",
            variant: "success",
          },
        })
      } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.Message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
      }
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    voidPRProcess() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_VOID_PAYMENT_REQUEST}`
      const body = {
        id: this.chosenClosePR,
        time: moment(),
      }
      axios.post(url, body, {
          headers,
      }).then(response => {
        if (response.data.success === true) {
            this.$refs.refPaymentRequestListTableVoid.refresh()
            this.$refs.refPaymentRequestListTablePaymentRequests.refresh()
            this.$refs.refPaymentRequestListTablePaymentRequestsNA.refresh()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Close PR Success",
            icon: "CheckIcon",
            variant: "success",
          },
        })
      } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.Message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
      }
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    voidPR(id) {
      this.chosenClosePR = id
      this.modalClosePR = true
    },
    rejectPR(id) {
      this.chosenClosePR = id
      this.modalReject = true
    },
    exportPR() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PAYMENT_REQUEST}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
            type: "ongoing",
          },
      }).then(response => {
        let filename = "report_payment_request"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    exportPRFinished() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PAYMENT_REQUEST}`
      this.isLoadingExportFinished = true

      axios.get(url, {
          headers,
          params: {
            startDate: this.startDateFilterFinished,
            endDate: this.endDateFilterFinished,
            type: "finished",
          },
      }).then(response => {
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Export Success",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Export Failed, Something went wrong on the server",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        }
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    deletePaymentRequest(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            this.$refs.refPaymentRequestListTablePaymentRequests.refresh()
            this.$refs.refPaymentRequestListTablePaymentRequestsNA.refresh()
            this.$refs.refPaymentRequestListTableSuppliesNA.refresh()
            this.$refs.refPaymentRequestListTableHONA.refresh()
            this.$refs.refPaymentRequestListTableSupplies.refresh()
            this.$refs.refPaymentRequestListTableHO.refresh()
            this.$refs.refPaymentRequestListTableFinishedPaymentRequests.refresh()
            this.$refs.refPaymentRequestListTableFinishedSupplies.refresh()
            this.$refs.refPaymentRequestListTableFinishedHO.refresh()
            this.$refs.refPaymentRequestListTableVoid.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    addNew() {
      this.$router.push({ name: "apps-payment-request-create" })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      localStorage.setItem("paymentRequestDetails", JSON.stringify(item))
      this.$router.push({ name: "apps-payment-request-detail" })
    },
    showMessages(paymentRequest) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.modalMessage = true
      this.paymentRequestId = paymentRequest.payment_request_id
      this.messagesList.splice(0, this.messagesList.length)
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PAYMENT_REQUEST_SINGLE}${this.paymentRequestId}`
      axios
        .get(
          url,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            console.log(response.data)
            if (response.data.data.chats) {
              this.messagesList = JSON.parse(response.data.data.chats)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    showReviseLog(item) {
      localStorage.setItem("paymentRequestDetails", JSON.stringify(item))
      this.$router.push({ name: "apps-payment-request-revise" })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
    async sendMessage() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const Message = this.messageBox
      const newMessage = {
        user_id: userId,
        user_fullname: this.userdata.user_fullname,
        chat_text: Message,
        chat_created_time: moment(),
      }
      this.messagesList.push(newMessage)
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PAYMENT_REQUEST_CHATS}`,
          {
            id: this.paymentRequestId,
            chats: this.messagesList,
          },
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.ref.messageListRef.refresh()
            this.$refs.refPaymentRequestListTablePaymentRequests.refresh()
            this.$refs.refPaymentRequestListTablePaymentRequestsNA.refresh()
            this.$refs.refPaymentRequestListTableSuppliesNA.refresh()
            this.$refs.refPaymentRequestListTableHONA.refresh()
            this.$refs.refPaymentRequestListTableSupplies.refresh()
            this.$refs.refPaymentRequestListTableHO.refresh()
            this.$refs.refPaymentRequestListTableFinishedPaymentRequests.refresh()
            this.$refs.refPaymentRequestListTableFinishedSupplies.refresh()
            this.$refs.refPaymentRequestListTableFinishedHO.refresh()
            this.$refs.refPaymentRequestListTableVoid.refresh()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        }).finally(() => {
          this.messageBox = ""
        })
    },
    showDocuments(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.paymentRequestId = id
      this.modalUpload = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}pr/${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            console.log("Fetching Data")
            if (response.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
    deleteAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
        id: document.document_id,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            this.showDocuments(document.document_payment_request_id)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Delete Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    downloadAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            window.open(response.data.Payload, "_blank")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    truncateString(str, maxLength) {
      if (str.length <= maxLength) {
          return str
      } else {
          return `${str.slice(0, maxLength - 2)}..`
      }
  },
    editPaymentRequest(item) {
      localStorage.setItem("paymentRequestDetails", JSON.stringify(item))
      this.$router.push({ name: "apps-payment-request-edit" })
    },
    sendDocument() {
      const userToken = this.$cookies.get('userToken')
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof this.file[0] !== 'undefined') {
      formData.append('file', this.file[0], this.file[0].name)
      formData.append('document_payment_request_id', this.paymentRequestId)
      formData.append('time', moment())
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_DOCUMENT}`, formData, { headers: header })
      .then(async response => {
        if (response.data.success === true) {
            this.$refs.refPaymentRequestListTablePaymentRequests.refresh()
            this.$refs.refPaymentRequestListTablePaymentRequestsNA.refresh()
            this.$refs.refPaymentRequestListTableSuppliesNA.refresh()
            this.$refs.refPaymentRequestListTableHONA.refresh()
            this.$refs.refPaymentRequestListTableSupplies.refresh()
            this.$refs.refPaymentRequestListTableHO.refresh()
            this.$refs.refPaymentRequestListTableFinishedPaymentRequests.refresh()
            this.$refs.refPaymentRequestListTableFinishedSupplies.refresh()
            this.$refs.refPaymentRequestListTableFinishedHO.refresh()
            this.$refs.refPaymentRequestListTableVoid.refresh()
            this.$refs.uploadFile.reset()
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}pr/${this.paymentRequestId}`,
          { headers: header },
        )
        .then(response2 => {
          if (response2.data.success === true) {
            if (response2.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response2.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Payment Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Upload The Document!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
.currency-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.currency-symbol {
  float: left;
}

.amount {
  float: right;
  text-align: right;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>